import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, state, style, animate } from '@angular/animations';

@Component({
  selector: 'app-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.less'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}), 
        animate(600)
      ]),
      transition(':leave', [
        animate(600, style({opacity: 0}))
      ])
    ])
  ]
})
export class ServiceItemComponent implements OnInit {

  private _service: any[] = [];

  constructor() { }

  get service(): any {
    return this._service;
  }

  @Input() set service(value: any) {
    this._service = value;
  }

  ngOnInit() {

  }
}
