export class GalleryBlueprint {
    imageUrlPattern: string = 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/gallery-image-${index}.jpg';

    data: string[] = [];

    constructor() {
        let images = this.range(30, 1).map(x => this.imageUrlPattern.replace('${index}', x.toString().padStart(2, '0')));
        this.data = this.shuffle(images).slice(0, 12);
    }

    range(size: number, startAt: number = 0) {
        return Array.apply(null, Array(size)).map(function (_, i) { return i + startAt; })
    }

    shuffle(array: Array<string>) {
        for (var i = array.length; i > 0; --i) {
            array.push(array.splice(Math.random() * i | 0, 1)[0]);
        }            
        return array;
    }
}
