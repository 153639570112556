<section id="barbers" class="view-section dark dark-background-image">
  <div class="view-section-inner">

    <div class="view-section-icon">
      <span class="icon fticon-mustache"></span>
    </div>

    <app-section-title [content]="'index.barbersTitle' | translate" [color]="'white'"></app-section-title>

    <div class="arrange-content">
      <div class="barbers-collection-container">
        <div class="barber-container" *ngFor="let barber of barbers">
          <div class="barber-photo-container">
            <img src="{{barber.photoUrl}}">
          </div>
          <div class="text-container">
            <h3 class="title">{{barber.name}}</h3>
          </div>
        </div>
      </div>

      <div class="view-section-label">
        <span>{{ 'index.barbersFooter' | translate }}</span>
      </div>
    </div>

    <app-section-separator [color]="'white'"></app-section-separator>
  </div>
</section>