import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  constructor(public translate:TranslateService) {
    translate.addLangs(['uk', 'en']);
    translate.setDefaultLang('uk');

    const browserLang = translate.getBrowserLang();
    const preferredLang = localStorage.getItem('preferences.language');
    const language = !preferredLang ? browserLang : preferredLang;

    const languageToUse = language.match(/uk|en/) ? language : 'uk';

    translate.use(languageToUse);
  }
}
