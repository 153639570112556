import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, query, stagger, animateChild, style, animate } from '@angular/animations';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-index-services-simplified',
  templateUrl: './index-services-simplified.component.html',
  styleUrls: ['./index-services-simplified.component.less'],
  animations: [
    trigger('list', [
      transition(':enter', [
        query('@items', stagger('1s', animateChild()), { optional: true })
      ]),
    ]),
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scaleY(0.9)', opacity: .2 }),
        animate('1s ease-out',
          style({ transform: 'scaleY(1)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'scaleY(1)', opacity: 1 }),
        animate('.1s ease-in',
          style({ transform: 'scaleY(0.9)', opacity: .2 }))
      ]),
    ])
  ],
})
export class IndexServicesSimplifiedComponent implements OnInit {

  private _services: Array<any> = [];

  get services(): Array<any> {
    return this._services;
  }

  @Input() set services(value: Array<any>) {
    this._services = value;
    this.filterServicesByCurrentPreset();
  }

  servicesSource: Array<any> = [];
  currentPreset: string = null;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  toggleFilter(preset) {
    this.currentPreset = preset;
    this.filterServicesByCurrentPreset();
  }

  filterServicesByCurrentPreset() {
    this.clearServicesSource();
    this.populateServicesSource();
    this.setContainerHeight();
  }

  clearServicesSource() {
    this.servicesSource.splice(0, this.servicesSource.length);
  }

  populateServicesSource() {
    if (!this._services || this._services.length === 0) {
      return;
    }

    for(var i = 0; i < this._services.length; i++) {
      let service = this._services[i];
      let language = this.translate.currentLang;
      let localizedName = service.name;

      if (this.currentPreset === null) {
        if (service.metadata.name[language]) {
          localizedName = service.metadata.name[language];
        }

        this.servicesSource.push({
          name: localizedName,
          duration: `${service.skuSummary.minDuration}-${service.skuSummary.maxDuration}`,
          price: `${service.skuSummary.minPrice}-${service.skuSummary.maxPrice}`
        });
      }
      
      if (!service.sku[this.currentPreset]) {
        continue;
      }

      var variation = service.sku[this.currentPreset];
      if (variation.metadata.name[language]) {
        localizedName = variation.metadata.name[language];
      }

      this.servicesSource.push({
        name: localizedName,
        duration: variation.duration,
        price: variation.price
      });
    }
  }

  setContainerHeight() {
    var height = (this.servicesSource.length * 82) + 12;  
    $('section#services .index-services-container').css({ height: `${height}px` })
  }

  trackServicesBy(item: any) : string {
    return item.id;
  }

  delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
  }
}
