import { Injectable } from '@angular/core';

import moment from "moment-timezone";

import { FacadeDataApiService } from '../api/facade-data-service.service';
import { FacadeDataModel } from 'src/app/models/facade-data-model';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {

  _timeZoneId = 'Europe/Kiev';

  data: FacadeDataModel;
  dataPromise: Promise<FacadeDataModel>;
  locale: any;

  constructor(private facadeDataApi: FacadeDataApiService) {
    this.locale = {
      language: 'uk',
      cultureCode: 'uk-ua',
      timeZoneId: this._timeZoneId,
      timeZone: moment.tz.zone(this._timeZoneId)
    };
  }


  getData(date: Date, culture: string): Promise<FacadeDataModel> {
    if (this.data) {
      Promise.resolve(this.data);
    }

    if (this.dataPromise) {
      return this.dataPromise;
    }

    console.log("executing call to API");

    this.dataPromise = this.facadeDataApi.getData(new Date(), "uk").toPromise();
    return this.dataPromise;
  }
}
