import { Component, OnInit } from '@angular/core';
import { ServiceModel } from 'src/app/models/service-model';
import { EmployeeModel } from 'src/app/models/employee-model';
import { DataStoreService } from 'src/app/core/services/data-store.service';
import { GalleryImageModel } from 'src/app/models/gallery-image-model';
import { ServicesBlueprint } from 'src/app/data/plants/services-blueprint';
import { EmployeesBlueprint } from 'src/app/data/plants/employees-blueprint';
import { GalleryBlueprint } from 'src/app/data/plants/gallery-blueprint';

@Component({
  selector: 'app-index-view',
  templateUrl: './index-view.component.html',
  styleUrls: ['./index-view.component.less'],
  host: {'class': 'view-host'}
})

export class IndexViewComponent implements OnInit {
  services: ServiceModel[];
  barbers: EmployeeModel[];
  galleryImages: string[];

  constructor(private dataService: DataStoreService) { }

  ngOnInit() {
    var services = new ServicesBlueprint();
    var employees = new EmployeesBlueprint();
    var gallery = new GalleryBlueprint();

    console.log(services.data);
    console.log(employees.data);
    console.log(gallery.data);

    this.services = services.data;
    this.barbers = employees.data;
    this.galleryImages = gallery.data;
  }

}
