import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.less']
})
export class SectionTitleComponent implements OnInit {

  @Input() content: string;
  @Input() color: string;

  constructor() { }

  ngOnInit() {
    if (!this.color) {
      this.color = "white";
    }
  }
}