import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';


let triggerEvent = function(el: any, etype: any){
  if (el.fireEvent) {
    el.fireEvent('on' + etype);
  } else {
    var evObj = document.createEvent('Events');
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}

@Component({
  selector: 'app-index-intro',
  templateUrl: './index-intro.component.html',
  styleUrls: ['./index-intro.component.less']
})
export class IndexIntroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  handleBookingClick(e: any) {
    e.stopPropagation();
    triggerEvent.apply(window, [$('.yButton')[0], 'click']);
  }

}
