<section id="contacts" class="view-section light light-background-image">
  <div class="view-section-inner">
    <div class="view-section-icon">
      <span class="icon fticon-map-label"></span>
    </div>

    <app-section-title [content]="'index.contactTitle' | translate" [color]="'black'"></app-section-title>

    <div class="view-section-label">
      <span>{{'index.contactSubtitle' | translate}}</span>
    </div>

    <div class="venues">
      <div class="venue-container">
        <h3 class="venue-title">{{'index.contacts.venue1' | translate}}</h3>

        <app-google-map latitude="49.838472" longitude="24.0379699"></app-google-map>

        <div style="display: flex; justify-content: center; flex-wrap: wrap;">
          <a mat-button href="https://www.google.com.ua/maps/place/Rymlyanyna+St,+5,+L'viv,+Lviv+Oblast/"
            target="_blank">
            <i class="material-icons" style="font-size: 22px; margin-inline-end: 6px;">directions</i>
            <span>{{'index.contacts.venue1Address' | translate}}</span>
          </a>

          <a mat-button href="tel:+380634884848">
            <i class="material-icons" style="font-size: 22px; margin-inline-end: 6px;">call</i>
            <span>+38&nbsp;(063)&nbsp;4884848</span>
          </a>

          <a mat-button href="mailto://hi@fattonysbarbers.com">
            <i class="material-icons" style="font-size: 22px; margin-inline-end: 6px;">alternate_email</i>
            <span>hi@fattonysbarbers.com</span></a>
        </div>
      </div>

      <div class="venue-container">
        <h3 class="venue-title">{{'index.contacts.venue2' | translate}}</h3>

        <app-google-map latitude="49.8551882" longitude="24.025089"></app-google-map>

        <div style="display: flex; justify-content: center; flex-wrap: wrap;">
          <a mat-button
            href="https://www.google.com/maps/place/49%C2%B051'18.9%22N+24%C2%B001'31.5%22E/@49.8552469,24.0248442,192m/"
            target="_blank">
            <i class="material-icons" style="font-size: 22px; margin-inline-end: 6px;">directions</i>
            <span>{{'index.contacts.venue2Address' | translate}}</span>
          </a>

          <a mat-button href="tel:+380980822389">
            <i class="material-icons" style="font-size: 22px; margin-inline-end: 6px;">call</i>
            <span>+38&nbsp;(098)&nbsp;0822389</span>
          </a>

          <a mat-button href="mailto://hi@fattonysbarbers.com">
            <i class="material-icons" style="font-size: 22px; margin-inline-end: 6px;">alternate_email</i>
            <span>hi@fattonysbarbers.com</span></a>
        </div>
      </div>
    </div>

    <div class="text-center" style="padding-top: 40px; padding-bottom: 20px;">
      <p class="text-center">Copyright © Fat Tony's Barbershop 2019</p>
    </div>
  </div>
</section>