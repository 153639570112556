<div class="_container" [ngStyle]="{'color': color}">
  <div style="flex: 0.2;">
    <div class="barbers-separator">
      <div [ngStyle]="{'border-color': color}">&nbsp;</div>
    </div>
  </div>

  <div class="barbers-separator">
    <span class="i icon fticon-comb-shaver"></span>
  </div>

  <div style="flex: 0.2;">
    <div class="barbers-separator">
      <div [ngStyle]="{'border-color': color}">&nbsp;</div>
    </div>
  </div>
</div>