
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { DatePipe } from '@angular/common';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppMaterialModule } from './app-material-module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IndexViewComponent } from './views/index-view/index-view.component';
import { IndexBarbersComponent } from './components/partial/index-barbers/index-barbers.component';
import { IndexContactComponent } from './components/partial/index-contact/index-contact.component';
import { IndexIntroComponent } from './components/partial/index-intro/index-intro.component';
import { FacadeDataApiService } from './core/api/facade-data-service.service';
import { DataStoreService } from './core/services/data-store.service';
import { SectionTitleComponent } from './components/primitives/section-title/section-title.component';
import { SectionSeparatorComponent } from './components/primitives/section-separator/section-separator.component';
import { GoogleMapComponent } from './components/primitives/google-map/google-map.component';
import { IndexGalleryComponent } from './components/partial/index-gallery/index-gallery.component';
import { IndexServicesSimplifiedComponent } from './components/partial/index-services-simplified/index-services-simplified.component';
import { LinkComponent } from './components/primitives/link/link.component';
import { ServiceItemComponent } from './components/primitives/service-item/service-item.component';
import { LanguageSwitcherComponent } from './components/partial/language-switcher/language-switcher.component';
import { AnimatedHeightContainerComponent } from './components/primitives/animated-height-container/animated-height-container.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '.json?cacheBuster=19120801');
}

@NgModule({
  declarations: [
    AppComponent,
    IndexViewComponent,
    IndexBarbersComponent,
    IndexContactComponent,
    IndexIntroComponent,
    SectionTitleComponent,
    SectionSeparatorComponent,
    GoogleMapComponent,
    IndexGalleryComponent,
    IndexServicesSimplifiedComponent,
    LinkComponent,
    ServiceItemComponent,
    LanguageSwitcherComponent,
    AnimatedHeightContainerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppMaterialModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TranslateModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [FacadeDataApiService, DataStoreService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
