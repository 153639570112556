import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FacadeDataModel } from 'src/app/models/facade-data-model';

@Injectable({
  providedIn: 'root'
})
export class FacadeDataApiService {

  private _url: string;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe
  ) {
    this._url = 'https://api.fattonysbarbers.com' + '/facade/';
  }

  private _ds(date: Date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  getData(date: Date, culture: string): Observable<FacadeDataModel> {
    return this.http.get<FacadeDataModel>(this._url + "data?date=" + this._ds(date) + "&culture=" + culture);
  };
}
