<section id="intro" class="view-section light">
  <div style="min-height: 12px; background-color: #005bbc;"></div>
  <div style="min-height: 12px; background-color: #ffd600;"></div>
  <div class="view-services-inner">

    <app-language-switcher></app-language-switcher>

    <div style="padding-top: 40px; padding-left: 0; padding-right: 0;">
      <p class="intro-greeting">{{ 'index.greeting' | translate }}</p>

      <div class="brand-heading">
        <img class="intro-logo" alt="Logo image" src="/assets/images/logo.png" style="width: 100%; max-width: 400px;">
      </div>

      <div class="booking-button-container">
        <div class="booking-button-inner">
          <button class="booking-button" matRipple (click)="handleBookingClick($event)">{{ 'index.book' | translate }}</button>
        </div>
      </div>

      <h1 class="intro-text font-roboto-light">{{ 'index.subtitle1' | translate }}</h1>
      <h2 class="intro-text font-roboto-light">{{ 'index.subtitle2' | translate }}</h2>
    </div>

    <div class="social container-fluid">
      <ul class="list-inline banner-social-buttons">
        <li>
          <a href="https://facebook.com/fattonysbarbers" target="blank" class="btn-social">
            <i class="fa fa-facebook fa-fw"></i>
          </a>
        </li>
        <li>
          <a href="https://instagram.com/fattonysbarbers" target="blank" class="btn-social">
            <i class="fa fa-instagram fa-fw"></i>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/fattonysbarbers" target="blank" class="btn-social">
            <i class="fa fa-twitter fa-fw"></i>
          </a>
        </li>
      </ul>
    </div>

    <div class="container text-center copyright">
      <p>Copyright © Fat Tony's Barbershop</p>
    </div>
  </div>
</section>