import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeModel } from 'src/app/models/employee-model';

@Component({
  selector: 'app-index-barbers',
  templateUrl: './index-barbers.component.html',
  styleUrls: ['./index-barbers.component.less']
})
export class IndexBarbersComponent implements OnInit {

  @Input() barbers: EmployeeModel[];

  constructor(private router: Router) { }

  ngOnInit() {
  }

  handleBarberSelected (barber: EmployeeModel) {
    if (!barber || !barber.id) return;
    this.router.navigate(['/book/barber/' + barber.id]);
  }
}
