import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-section-separator',
  templateUrl: './section-separator.component.html',
  styleUrls: ['./section-separator.component.less']
})
export class SectionSeparatorComponent implements OnInit {
  @Input() color: string;

  constructor() { }

  ngOnInit() {
    if (!this.color) {
      this.color = "white";
    }
  }
}
