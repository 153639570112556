import { ServiceModel } from 'src/app/models/service-model';

export class ServicesBlueprint {

    data: ServiceModel[] = [
        {
            id: '3300591',
            name: 'Стрижка',
            metadata: {
                icon: 'scissors-comb',
                name: {
                    uk: 'Стрижка',
                    en: 'Haircut'
                },
                nameAccusative: {
                    uk: 'Стрижку',
                    en: 'Haircut'
                }
            },
            skuSummary: {
                minPrice: 150,
                maxPrice: 525,
                minDuration: 60,
                maxDuration: 75
            },
            sku: {
                junior: {
                    id: '3300741',
                    name: 'Стрижка у молодшого барбера',
                    duration: 75,
                    price: 150,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            uk: 'Стрижка у молодшого барбера',
                            en: 'Haircut - Junior Barber'
                        },
                        nameAccusative: {
                            uk: 'Стрижку в молодшого барбера',
                            en: 'Haircut - Junior Barber'
                        }
                    }
                },
                default: {
                    id: '3300591',
                    name: 'Стрижка',
                    duration: 60,
                    price: 250,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            uk: 'Стрижка',
                            en: 'Haircut'
                        },
                        nameAccusative: {
                            uk: 'Стрижку',
                            en: 'Haircut'
                        }
                    }
                },
                top: {
                    id: '3300757',
                    name: 'Стрижка у старшого майстра',
                    duration: 60,
                    price: 350,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            uk: 'Стрижка у старшого майстра',
                            en: 'Haircut at Head Barber'
                        },
                        nameAccusative: {
                            uk: 'Стрижку в старшого майстра',
                            en: 'Haircut at Head Barber'
                        }
                    }
                },
                head: {
                    id: '025d0573-f313-4ec2-b6bd-02d886728244',
                    name: '3300617',
                    duration: 60,
                    price: 500,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            uk: 'Стрижка від Святослава',
                            en: 'Haircut from Svyatoslav'
                        },
                        nameAccusative: {
                            uk: 'Стрижку від Святослава',
                            en: 'Haircut from Svyatoslav'
                        }
                    }
                },
                head1: {
                    id: '3484052',
                    name: 'Стрижка від Андрія',
                    duration: 60,
                    price: 450,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            uk: 'Стрижка від Андрія',
                            en: 'Haircut from Andrew'
                        },
                        nameAccusative: {
                            uk: 'Стрижку від Андрія',
                            en: 'Haircut from Andrew'
                        }
                    }
                }
            }
        },
        {
            id: '3300596',
            name: 'Стрижка бороди',
            metadata: {
                icon: 'scissors-comb',
                name: {
                    uk: 'Стрижка бороди',
                    en: 'Beard trim'
                },
                nameAccusative: {
                    uk: 'Стрижку бороди',
                    en: 'Beard trim'
                }
            },
            skuSummary: {
                minPrice: 150,
                maxPrice: 500,
                minDuration: 60,
                maxDuration: 75
            },
            sku: {
                junior: {
                    id: '3300742',
                    name: 'Стрижка бороди у молодшого барбера',
                    duration: 75,
                    price: 150,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            uk: 'Стрижка бороди у молодшого барбера',
                            en: 'Beard Trim - Junior Barber'
                        },
                        nameAccusative: {
                            uk: 'Стрижку бороди у молодшого барбера',
                            en: 'Beard Trim - Junior Barber'
                        }
                    }
                },
                default: {
                    id: '3300596',
                    name: 'Стрижка бороди',
                    duration: 45,
                    price: 250,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            uk: 'Стрижка бороди',
                            en: 'Beard trim'
                        },
                        nameAccusative: {
                            uk: 'Стрижку бороди',
                            en: 'Beard trim'
                        }
                    }
                },
                top: {
                    id: '3300759',
                    name: 'Стрижка бороди у старшого майстра',
                    duration: 45,
                    price: 350,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            uk: 'Стрижка бороди у старшого майстра',
                            en: 'Trim at Head Barber'
                        },
                        nameAccusative: {
                            uk: 'Стрижку бороди у старшого майстра',
                            en: 'Trim at Head Barber'
                        }
                    }
                },
                head: {
                    id: '3300618',
                    name: 'Стрижка бороди від Святослава',
                    duration: 45,
                    price: 500,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            uk: 'Стрижка бороди чи гоління у Святослава',
                            en: 'Beard trim or shave from Svayatoslav'
                        },
                        nameAccusative: {
                            uk: 'Стрижку бороди чи гоління у Святослава',
                            en: 'Beard trim or shave from Svayatoslav'
                        }
                    }
                },
                head1: {
                    id: '3484055',
                    name: 'Стрижка бороди від Андрія',
                    duration: 45,
                    price: 450,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            uk: 'Стрижка бороди чи гоління у Андрія',
                            en: 'Beard trim or shave from Andrew'
                        },
                        nameAccusative: {
                            uk: 'Стрижку бороди чи гоління у Андрія',
                            en: 'Beard trim or shave from Andrew'
                        }
                    }
                }
            }
        },
        {
            id: '3300597',
            name: 'Класичне гоління',
            metadata: {
                icon: 'scissors-comb',
                name: {
                    'uk': 'Класичне гоління',
                    'en': 'Shave'
                },
                nameAccusative: {
                    'uk': 'Класичне гоління',
                    'en': 'Shave'
                }
            },
            skuSummary: {
                minPrice: 250,
                maxPrice: 500,
                minDuration: 60,
                maxDuration: 75
            },
            sku: {
                junior: null,
                default: {
                    id: '3300597',
                    name: 'Класичне гоління',
                    duration: 45,
                    price: 250,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            'uk': 'Класичне гоління',
                            'en': 'Shave'
                        },
                        nameAccusative: {
                            'uk': 'Класичне гоління',
                            'en': 'Shave'
                        }
                    }
                },
                top: {
                    id: '018c4f3a-0108-498a-ab03-aa13cef7a22a',
                    name: 'Класичне гоління у старшого майстра',
                    duration: 45,
                    price: 350,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            'uk': 'Класичне гоління у старшого майстра',
                            'en': 'Shave at Head barber'
                        },
                        nameAccusative: {
                            'uk': 'Класичне гоління у старшого майстра',
                            'en': 'Shave at Head barber'
                        }
                    }
                },
                head: {
                    id: '0dadc733-2aca-46fe-b115-2845637a7d81',
                    name: 'Гоління у Святослава',
                    duration: 45,
                    price: 500,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            uk: 'Гоління у Святослава',
                            en: 'Shave from Svayatoslav'
                        },
                        nameAccusative: {
                            uk: 'Гоління у Святослава',
                            en: 'Shave from Svayatoslav'
                        }
                    }
                },
                head1: {
                    id: '0dadc733-2aca-46fe-b115-2845637a7d81',
                    name: 'Гоління у Андрія',
                    duration: 45,
                    price: 450,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            uk: 'Гоління від Андрія',
                            en: 'Shave from Andrew'
                        },
                        nameAccusative: {
                            uk: 'Гоління від Святослава',
                            en: 'Shave from Andrew'
                        }
                    }
                }
            }
        },
        {
            id: 'b2a1e495-7835-4ae7-afce-db42ce72dfa8',
            name: 'Стрижка та стрижка бороди',
            metadata: {
                icon: 'scissors-comb',
                name: {
                    'uk': 'Стрижка та стрижка бороди',
                    'en': 'Cut & Trim'
                },
                nameAccusative: {
                    'uk': 'Стрижку та стрижку бороди',
                    'en': 'Cut & Trim'
                }
            },
            skuSummary: {
                minPrice: 250,
                maxPrice: 800,
                minDuration: 60,
                maxDuration: 75
            },
            sku: {
                junior: {
                    id: '9c96776e-44c9-448e-ac80-7983bf037923',
                    name: 'Стрижка та стрижка бороди у молодшого барбера',
                    duration: 105,
                    price: 250,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            'uk': 'Стрижка та стрижка бороди у молодшого барбера',
                            'en': 'Haircut & Beard trim at Junior Barber'
                        },
                        nameAccusative: {
                            'uk': 'Стрижку та стрижку бороди у молодшого барбера',
                            'en': 'Haircut & Beard trim at Junior Barber'
                        }
                    }
                },
                default: {
                    id: 'b2a1e495-7835-4ae7-afce-db42ce72dfa8',
                    name: 'Стрижка та стрижка бороди',
                    duration: 90,
                    price: 400,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            'uk': 'Стрижка та стрижка бороди',
                            'en': 'Cut & Trim'
                        },
                        nameAccusative: {
                            'uk': 'Стрижку та стрижку бороди',
                            'en': 'Cut & Trim'
                        }
                    }
                },
                top: {
                    id: '6e001a45-1a4a-49ff-bffb-a63656b65bf6',
                    name: 'Комлпекс послуг у старшого майстра',
                    duration: 90,
                    price: 600,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            'uk': 'Комлпекс послуг у старшого майстра',
                            'en': 'Haircut & Trim or Shave from Head Barber'
                        },
                        nameAccusative: {
                            'uk': 'Комлпекс послуг у старшого майстра',
                            'en': 'Haircut & Trim or Shave from Head Barber'
                        }
                    }
                },
                head: {
                    id: '025d0573-f313-4ec2-b6bd-02d886728244',
                    name: 'Комплексна послуга у Святослава',
                    duration: 90,
                    price: 800,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            'uk': 'Комплексна послуга у Святослава',
                            'en': 'Haircut & Trim or Shave from Svyatoslav'
                        },
                        nameAccusative: {
                            'uk': 'Комплексну послугу у Святослава',
                            'en': 'Haircut & Trim or Shave from Svyatoslav'
                        }
                    }
                },
                head1: {
                    id: '025d0573-f313-4ec2-b6bd-02d886728244',
                    name: 'Комплексна послуга у Андрія',
                    duration: 90,
                    price: 700,
                    metadata: {
                        icon: 'scissors-comb',
                        name: {
                            'uk': 'Комплексна послуга у Андрія',
                            'en': 'Haircut & Trim or Shave from Svyatoslav'
                        },
                        nameAccusative: {
                            'uk': 'Комплексну послугу у Андрія',
                            'en': 'Haircut & Trim or Shave from Svyatoslav'
                        }
                    }
                }
            }
        }
    ]
}
