import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.less']
})
export class LanguageSwitcherComponent implements OnInit {

  constructor(private translate: TranslateService) {
    this.language = this.translate.currentLang;
  }

  language: string;

  ngOnInit() {
  }

  setLanguage(language: string) {
    this.translate.use(language);
    this.language = this.translate.currentLang;
    localStorage.setItem('preferences.language', this.translate.currentLang);
  }

}
