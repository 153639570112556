<section id="gallery" class="view-section dark dark-background-image">
  <div class="view-section-inner">

    <div class="view-section-icon">
      <span class="icon fticon-mustache"></span>
    </div>

    <app-section-title [content]="'index.galleryTitle' | translate" [color]="'white'"></app-section-title>

    <div class="view-section-label">
      <span>{{'index.gallerySubtitle' | translate}}</span>
    </div>

    <div class="arrange-content">
      <div class="gallery-images-container">
        <div class="gallery-image" *ngFor="let image of galleryImages">
          <a class="inner" href="javascript:void(0)" [style.background-image]="'url(' + image + ')'">
          </a>
        </div>
      </div>
    </div>

    <app-section-separator></app-section-separator>
  </div>
</section>