<section id="services" class="view-section light light-background-image">
  <div class="view-section-inner">

    <div class="view-section-icon">
      <span class="icon fticon-comb-shaver"></span>
    </div>

    <app-section-title [content]="'index.servicesTitle' | translate" [color]="'black'"></app-section-title>

    <div class="view-section-label">
      <span>{{'index.servicesSubtitle' | translate}}</span>
    </div>

    <div class="arrange-content">

      <div class="text-center">
        <div style="text-align: center; margin: 24px;">
          <button mat-button (click)="toggleFilter(null)" style="border: 0px solid transparent">
            <span class="text-uppercase">{{'index.servicesCategories.all' | translate}}</span>
          </button>
          <span class="hidden-small" style="margin: 0 10px;">|</span>
          <button mat-button (click)="toggleFilter('default')" style="border: 0px solid transparent">
            <span class="text-uppercase">{{'index.servicesCategories.barber' | translate}}</span>
          </button>
          <span class="hidden-small" style="margin: 0 10px;">|</span>
          <button mat-button (click)="toggleFilter('junior')" style="border: 0px solid transparent">
            <span class="text-uppercase">{{'index.servicesCategories.junior' | translate}}</span>
          </button>
          <span class="hidden-small" style="margin: 0 10px;">|</span>
          <button mat-button (click)="toggleFilter('top')" style="border: 0px solid transparent">
            <span class="text-uppercase">{{'index.servicesCategories.senior' | translate}}</span>
          </button>
          <span class="hidden-small" style="margin: 0 10px;">|</span>
          <button mat-button (click)="toggleFilter('head')" style="border: 0px solid transparent">
            <span class="text-uppercase">{{'index.servicesCategories.head' | translate}}</span>
          </button>
          <span class="hidden-small" style="margin: 0 10px;">|</span>
          <button mat-button (click)="toggleFilter('head1')" style="border: 0px solid transparent">
            <span class="text-uppercase">{{'index.servicesCategories.head1' | translate}}</span>
          </button>
        </div>
      </div>

      <div @list class="index-services-container">
        <div @items class="uic-service" *ngFor="let service of servicesSource; trackBy: trackServicesBy">
          <div style="display: flex; flex-direction: row;">
            <div>
              <span class="font-roboto-regular">{{service.name}}</span>
              <br>
              <small class="font-ptsans-regular">
                <span>{{'common.duration' | translate}}:</span>&nbsp;
                <span>{{service.duration}}</span>&nbsp;
                <span>{{'common.minutes' | translate}}</span>
              </small>
            </div>
            <div style="flex:1"></div>
            <div style="padding-top: 10px;">
              <span class="font-ptsans-regular">{{service.price}}&nbsp;<span
                  style="font-family: sans-serif;">₴</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="view-section-label">
      <span class="font-ptsans-regular">{{'index.servicesFooter' | translate}}</span>
    </div>

    <app-section-separator [color]="'black'"></app-section-separator>
  </div>
</section>