import { EmployeeModel } from 'src/app/models/employee-model';

export class EmployeesBlueprint {
    data: EmployeeModel[] = [{
        'id': 'bez2jl9hh2',
        'name': 'Святослав Заєць',
        'displayName': 'Святослав',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees_new_photo/bez2jl9hh2.png',
        'metadata': {
            'name': {
                'uk': 'Святослав',
                'en': 'Svyatoslav'
            }
        }
    }, {
        'id': 'trubf2ouq2',
        'name': 'Олесь Шевчук',
        'displayName': 'Олесь',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees-2024/trubf2ouq2.jpg',
        'metadata': {
            'name': {
                'uk': 'Олесь',
                'en': 'Oles'
            }
        }
    }, {
        'id': '6722fdrxny',
        'name': 'Дмитро Чернов',
        'displayName': 'Дмитро',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees-2024/6722fdrxny.jpg',
        'metadata': {
            'name': {
                'uk': 'Дмитро',
                'en': 'Dmytro'
            }
        }
    }, {
        'id': 'qzjqzibj6c',
        'name': 'Олександр Агапов',
        'displayName': 'Олександр',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees-2024/qzjqzibj6c.jpg',
        'metadata': {
            'name': {
                'uk': 'Олександр',
                'en': 'Olexandr'
            }
        }
    }, {
        'id': 'gzkvx30rk8',
        'name': 'Володимир Кухта',
        'displayName': 'Володимир',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees-2024/gzkvx30rk8.jpg',
        'metadata': {
            'name': {
                'uk': 'Володимир',
                'en': 'Volodymyr'
            }
        }
    },  {
        'id': '712761ff06',
        'name': 'Сергій',
        'displayName': 'Сергій',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees-2024/712761ff06.jpg',
        'metadata': {
            'name': {
                'uk': 'Сергій',
                'en': 'Segiy'
            }
        }
    }, {
        'id': '6n6ugja4sp',
        'name': 'Наталі',
        'displayName': 'Наталі',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees-2024/6n6ugja4sp.jpg',
        'metadata': {
            'name': {
                'uk': 'Наталі',
                'en': 'Natali'
            }
        }
    }, {
        'id': 'OcW9RSCnrf',
        'name': 'Михайло',
        'displayName': 'Михайло',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees-2024/OcW9RSCnrf.jpg',
        'metadata': {
            'name': {
                'uk': 'Михайло',
                'en': 'Mykhaylo'
            }
        }
    }, {
        'id': 'zJj4IWUuQH',
        'name': 'Надія',
        'displayName': 'Надія',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees-2024/zJj4IWUuQH.jpg',
        'metadata': {
            'name': {
                'uk': 'Надія',
                'en': 'Nadiya'
            }
        }
    }, {
        'id': 'ERc4LebSMC',
        'name': 'Аня',
        'displayName': 'Аня',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees-2024/ERc4LebSMC.jpg',
        'metadata': {
            'name': {
                'uk': 'Аня',
                'en': 'Anya'
            }
        }
    }, {
        'id': 'mRWQG5w9aC',
        'name': 'Сергій Сагайдак',
        'displayName': 'Сергій Сагайдак',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees-2024/mRWQG5w9aC.jpg',
        'metadata': {
            'name': {
                'uk': 'Сергій Сагайдак',
                'en': 'Serhiy Sagaydak'
            }
        }
    }, {
        'id': '7zCBctgIev',
        'name': 'Інна',
        'displayName': 'Інна',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees-2024/7zCBctgIev.jpg',
        'metadata': {
            'name': {
                'uk': 'Інна',
                'en': 'Інна'
            }
        }
    }, {
        'id': 'c3EdT4akCJ',
        'name': 'Наталі Онищенко',
        'displayName': 'Наталі Онищенко',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees-2024/IMG_5236.jpg',
        'metadata': {
            'name': {
                'uk': 'Наталі Онищенко',
                'en': 'Natalie Onyshchenko'
            }
        }
    }];
}
