import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-index-gallery',
  templateUrl: './index-gallery.component.html',
  styleUrls: ['./index-gallery.component.less']
})
export class IndexGalleryComponent implements OnInit {

  @Input()
  galleryImages: string[] = [];

  constructor() { }

  ngOnInit() {

  }
}
