<div style="display: flex; flex-direction: row;">
  <div>
    <span class="font-roboto-regular">{{service.name}}</span>
    <br>
    <small class="font-ptsans-regular">Тривалість: {{service.skuSummary.minDuration}}-{{service.skuSummary.maxDuration}}
      хвилин</small>
  </div>
  <div style="flex:1"></div>
  <div style="padding-top: 10px;">
    <span class="font-ptsans-regular">{{service.skuSummary.minPrice}}-{{service.skuSummary.maxPrice}}&nbsp;<span
        style="font-family: sans-serif;">₴</span></span>
  </div>
</div>